import BaseBlockContent from '@sanity/block-content-to-react'
import React from 'react'

const serializers = {
  types: {
    block (props) {
      return <h2>{props.children}</h2>
    }
  }
}

const SimpleH2 = ({ blocks }) => <BaseBlockContent blocks={blocks} serializers={serializers} />

export default SimpleH2
