import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../modules/layout"

import SEO from "../../components/seo"
import Breadcrumbs from "../../modules/breadcrumbs"
import SimpleHero from "../../modules/simpleHero"
import Grid from "../../modules/grid"
import Pagination from "../../components/pagination"

export const query = graphql`
  query($skip: Int, $limit: Int) {
    allSanityEvent(
      sort: { order: DESC, fields: dateStart }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          _type
          title
          excerpt
          link
          slug {
            current
          }
          publishedAt(formatString: "MMM D, YYYY")
          dateStart(formatString: "MM/DD/YYYY")
          dateEnd(formatString: "MM/DD/YYYY")
          location
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

const Events = ({ data, pageContext, location }) => {
  const events = data.allSanityEvent.edges
  const title = `Events`
  const description = null
  const image = null

  return (
    <Layout>
      <SEO title={title} description={description} image={image} />
      <Breadcrumbs location={location}>
        <Link to="/">Home</Link>
        <p>Events</p>
      </Breadcrumbs>
      <SimpleHero heading="PeopleFluent Events" />
      <section className="blocksGrid">
        {events && <Grid blocks={events} moreCols />}
        {pageContext.eventsPages > 1 && (
          <Pagination
            type="/why-peoplefluent/events"
            currentPage={pageContext.currentPage}
            totalPages={pageContext.eventsPages}
          />
        )}
      </section>
    </Layout>
  )
}

export default Events
