import React, { useMemo } from "react"
import Img from "gatsby-image/withIEPolyfill"
import cx from "classnames"
import Button from "./button"

import styles from "./blockItem.module.scss"

const labels = {
  newsroom: "Newsroom",
  caseStudy: "Case studies",
}

const types = {
  blog: "blog",
  event: "events",
  newsroom: "newsroom",
  caseStudy: "resources",
  resource: "resources",
  event: "why-peoplefluent/events",
}

const BlockItem = ({
  item,
  image,
  defaultImage,
  type,
  showCaseStudyCategory,
}) => {
  const isResource = !!item.contentType?.title
  const isBlog = type === "blog" && !!item.category?.title
  const isCaseStudy =
    type === "caseStudy" && showCaseStudyCategory && !!item.category?.title
  const isImage = image?.asset?.fluid || image?.asset?.url || defaultImage
  const isWebinar = item.contentType?.title?.toLowerCase().includes("webinar")
  const isEbook = item.contentType?.title?.toLowerCase().includes("ebook")
  const isProduct = item.contentType?.title?.toLowerCase().includes("product")
  const isAnalyst = item.contentType?.title?.toLowerCase().includes("analyst")

  const blockItemClass = cx(styles.blockItem, {
    [styles.blockItem__noImage]: !isImage,
  })

  const blockItemExcerptClass = cx(styles.blockItemExcerpt, {
    [styles.blockItemExcerpt__noImage]: !isImage,
  })

  const blockItemLabelClass = cx(styles.blockItemLabel, {
    [styles.blockItemLabel__purple]: isWebinar,
    [styles.blockItemLabel__aqua]: isEbook,
    [styles.blockItemLabel__lightPurple]: isProduct,
    [styles.blockItemLabel__darkPurple]: isAnalyst,
    [styles.blockItemLabel__pink]: type === "event",
    [styles.blockItemLabel__yellow]: type === "caseStudy",
  })

  const link = item.category
    ? `/${types[type]}/${item.category.slug.current}/${item.slug.current}`
    : item.slug
    ? `/${types[type]}/${item.slug.current}`
    : item.link

  const label = useMemo(() => {
    switch (true) {
      case isResource:
        return item.contentType.title
      case isCaseStudy:
      case isBlog:
        return item.category.title
      case !!labels[type]:
        return labels[type]
      default:
        return null
    }
  }, [isResource, isCaseStudy, isBlog, type, item])

  return (
    <div className={blockItemClass}>
      <div className={styles.blockItemMain}>
        {image?.asset?.fluid ? (
          <Img
            fluid={image.asset.fluid}
            objectFit="cover"
            alt={image.alt}
            className={styles.blockItemImage}
          />
        ) : image?.asset?.url ? (
          <div className={styles.blockItemImage}>
            <img alt="product" src={image.asset.url} />
          </div>
        ) : defaultImage ? (
          <div className={styles.blockItemImage}>
            <img alt="default product" src={defaultImage} />
          </div>
        ) : null}

        <h3 className={styles.blockItemTitle}>{item.title}</h3>
        {item.dateStart ? (
          <div className={styles.blockItemDate}>
            {item.dateStart} {item.dateEnd && `- ${item.dateEnd}`}
          </div>
        ) : null}
        <div title={item.excerpt} className={blockItemExcerptClass}>
          {item.excerpt}
        </div>
      </div>

      {label ? <p className={blockItemLabelClass}>{label}</p> : null}

      {link && (
        <Button
          className={styles.blockItemButton}
          type="secondary"
          to={link}
          label="Read more"
        />
      )}
    </div>
  )
}

export default BlockItem
