import generateBreadcrumbSchema from './generateBreadcrumbSchema';

function injectBreadcrumbSchema(breadcrumbs, origin) {
  let script = document.head.querySelector('script[type="application/ld+json"]');
  const schema = generateBreadcrumbSchema(breadcrumbs, origin);

  if (script) {
    script.text = JSON.stringify(schema);
    return;
  }

  script = document.createElement('script');
  script.type = 'application/ld+json';
  script.text = JSON.stringify(schema);
  document.head.appendChild(script);
}

export default injectBreadcrumbSchema;
