function generateBreadcrumbSchema(breadcrumbs, origin) {
  const flattenBreadcrumbs = (arr) => arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenBreadcrumbs(val)) : acc.concat(val), []);

  const flatBreadcrumbs = flattenBreadcrumbs(breadcrumbs);

  const itemListElement = flatBreadcrumbs.map((breadcrumb, index) => {
    const isChildrenObject = typeof breadcrumb.props.children === 'object';
    const name = isChildrenObject ? breadcrumb.props.children.props.children : breadcrumb.props.children;
    const url = breadcrumb.props.to;

    const item = {
      "@type": "ListItem",
      "position": index + 1,
      "name": name,
    };

    if (url && origin) {
      item.url = `${origin}${url}`;
    }

    return item;
  });

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": itemListElement,
  };
}

export default generateBreadcrumbSchema;
