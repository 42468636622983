import React, { useCallback } from "react"
import { Link } from "gatsby"
import styles from "./pagination.module.scss"

const threeDots = <li className={styles.threeDots}>...</li>

const Pagination = props => {
  const { currentPage, totalPages, type, updateCurrentPage } = props

  const prevPage =
    currentPage - 1 <= 1
      ? `/${type}`
      : `/${type}/page/${(currentPage - 1).toString()}`
  const nextPage =
    currentPage !== undefined && currentPage === totalPages
      ? `/${type}/page/${currentPage.toString()}`
      : `/${type}/page/${(currentPage + 1).toString()}`

  const onLinkClick = useCallback((event, page) => {
    if (updateCurrentPage) {
      event.preventDefault()
      updateCurrentPage(page)
    }
  }, [])

  const displayItem = (i, array) => {
    array.push(
      <li
        key={`pagination-${type}-${i}`}
        className={currentPage === i + 1 ? styles.current : ""}
      >
        <Link
          to={i === 0 ? `/${type}/` : `/${type}/page/${i + 1}/`}
          onClick={event => onLinkClick(event, i + 1)}
        >
          <span className={styles.visuallyHidden}>Page</span>
          {i + 1}
        </Link>
      </li>
    )
  }

  const paginate = () => {
    let html = []
    if (totalPages <= 6) {
      Array.from({ length: totalPages }, (_, i) => displayItem(i, html))
    } else if (currentPage < 5) {
      Array.from({ length: 5 }, (_, i) => displayItem(i, html))
      html.push(threeDots)
      displayItem(totalPages - 1, html)
    } else if (currentPage > 4 && currentPage < totalPages - 3) {
      displayItem(0, html)
      html.push(threeDots)
      for (let i = currentPage - 3; i < currentPage + 2; i++) {
        displayItem(i, html)
      }
      html.push(threeDots)
      displayItem(totalPages - 1, html)
    } else {
      displayItem(0, html)
      html.push(threeDots)
      for (let i = totalPages - 5; i < totalPages; i++) {
        displayItem(i, html)
      }
    }
    return html
  }

  return (
    <nav aria-label="pagination" className={styles.pagination}>
      <ul>
        <li className={currentPage === 1 ? styles.current : ""}>
          <Link
            to={prevPage}
            onClick={event => onLinkClick(event, currentPage - 1)}
          >
            Previous
          </Link>
        </li>
        {paginate()}
        <li className={currentPage === totalPages ? styles.current : ""}>
          <Link
            to={nextPage}
            onClick={event => onLinkClick(event, currentPage + 1)}
          >
            Next
          </Link>
        </li>
      </ul>
    </nav>
  )
}

export default Pagination
