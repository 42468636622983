import React from "react"
import cx from "classnames"

import SimpleH2 from "../components/block-content/simpleH2"

import styles from "./simpleHero.module.scss"

const SimpleHero = props => {
  const simpleHeroClass = cx(styles.simpleHero, {
    [styles.simpleHero__withoutBg]: props.withoutBg,
    [styles.simpleHero__splitLayout]: props.splitLayout,
    [props.className]: !!props.className,
  })

  return (
    <section className={simpleHeroClass}>
      <div className={styles.simpleHeroContent}>
        <h1>{props.heading}</h1>
        {props.tagline && <SimpleH2 blocks={props.tagline} />}
        {props.children}
      </div>
    </section>
  )
}

export default SimpleHero
