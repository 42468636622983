import React from "react"
import cx from "classnames"

import BlockItem from "../components/blockItem"

import styles from "./grid.module.scss"

const Grid = props => {
  const gridClass = cx(styles.gridListColumns, {
    [styles.gridListColumnsMore]: props.moreCols,
    [props.className]: !!props.className,
  })

  return (
    <ul className={gridClass}>
      {props.blocks.map(({ node: post }) => (
        <BlockItem
          key={post.id}
          item={post}
          image={post.image}
          defaultImage={props.defaultImage}
          type={post._type}
          showCaseStudyCategory={props.showCaseStudyCategory}
        />
      ))}
    </ul>
  )
}

export default Grid
