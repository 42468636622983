import React, { useMemo, useEffect } from "react"
import cx from "classnames"

import injectBreadcrumbSchema from "../helpers/injectBreadcrumbSchema";

import styles from "./breadcrumbs.module.scss"

const regex = /\/([^/]+)\/?$/

const Breadcrumbs = ({ children, alt, className, location }) => {
  const breadcrumbsClass = cx(styles.breadcrumbs, {
    [styles.breadcrumbsAlt]: alt,
    [className]: className,
  })

  useEffect(() => {
    injectBreadcrumbSchema(children, location?.origin);
  }, [children, location])

  useEffect(() => {
    const script = document.head.querySelector('script[type="application/ld+json"]');
    return () => {
      if (script && script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, []);

  const lastPathPart = useMemo(() => {
    const pathMatch = location?.pathname.match(regex)
    return pathMatch ? pathMatch[1] : null
  }, [location])

  return (
    <section className={breadcrumbsClass}>
      <div className={styles.breadcrumbsInner}>
        <ul>
          {React.Children.map(children, (child, index) => {
            const childMatch = child.props.to?.match(regex)
            const lastChildPart = childMatch ? childMatch[1] : null
            const isActive =
              (lastChildPart || lastChildPart) && lastChildPart === lastPathPart

              const isBold = child.props?.state?.bold;
              const isBefore = child.props?.state?.before;

            return (
              <li
                key={index}
                className={cx({
                  [styles.active]: isActive,
                  [styles.bold]: isBold,
                  [styles.before]: isBefore,
                })}
              >
                {React.cloneElement(child)}
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default Breadcrumbs
